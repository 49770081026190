import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Skeleton from "@mui/material/Skeleton";
const App = lazy(() => import("./App"));

const link = document.createElement("link");
link.rel = "stylesheet";
link.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
document.head.appendChild(link);

const themeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#f2e2c5",
      light: "rgb(242, 171, 96)",
      dark: "#f5f5f5",
      white: "#FFF",
      mellowWhite: "#FFF5F7",
      black: "#000",
      grey: "#7a7a7a",
      red: "#e81416",
      blue: "#487de7",
    },
    secondary: {
      main: "#D1D0CD",
      light: "rgb(244, 231, 208)",
      dark: "rgb(169, 158, 137)",
    },
    info: {
      main: "#2196f3",
      dark: "#00473d",
      light: "#a9fbda",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    textDecoration: "none",
    rubikDoodle: "Rubik Doodle Shadow",
    rubikMaps: "Rubik Maps",
    kanit: "Kanit",
    IBM: "IBM Plex Sans",
    fontWeightBold: 700,
  },
  customStyles: {
    logoBox: {
      height: "100%",
      width: {xs: "90%", md: "50%"},
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Skeleton />}>
          <Routes>
            <Route exact path="/" element={<App />} />
            <Route path="/classes" element={<App />} />
            <Route path="/memberships" element={<App />} />
            <Route path="/gallery" element={<App />} />
            <Route path="/about" element={<App />} />
            <Route path="/terms&conditions" element={<App />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
